import React from "react"
import { navigate } from "@reach/router"

const Team = ({data}) => {
    React.useEffect(() => {
        navigate('https://giftmallcorp.jp/company/member/');
    }, []);
    return null;
};

export default Team
